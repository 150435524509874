import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import NavBar from "../../ui/NavBar";
import UserRegistryPlantCard from "./UserRegistryPlantCard";
import useApiGetUserRegistries from "./api-hooks/useApiGetUserRegistries";
import {useLocation} from "react-router-dom";
import useApiGetUserRegistryPlants from "./api-hooks/useApiGetUserRegistryPlants";
import LoadingBar from "../../ui/LoadingBar";
import {IUserRegistryPlant} from "../../utils/interfaces/IUserRegistryPlant";

const PlantsScreen = () => {
    const [registryUserId, setRegistryUserId] = useState<number | undefined>()
    const [userRegistryPlants, setUserRegistryPlants] = useState<IUserRegistryPlant[]>()
    const routerState = useLocation().state

    const {
        data: userRegistriesData,
        error: userRegistriesError
    } = useApiGetUserRegistries(routerState.userId)
    if (userRegistriesError) {
        console.log(userRegistriesError)
    }

    const {
        data: userRegistryPlantsData,
        error: userRegistryPlantsError,
        isFetching: userRegistryPlantsIsFetching,
        isSuccess: userRegistryPlantsIsSuccess,
    } = useApiGetUserRegistryPlants(registryUserId)
    if (userRegistryPlantsError) {
        console.log(userRegistryPlantsError)
    }

    useEffect(() => {
        if (userRegistriesData && userRegistriesData[0].id) {
            setRegistryUserId(userRegistriesData[0].id)
        }
    }, [userRegistriesData]);

    useEffect(() => {
        if (userRegistryPlantsData) {
            setUserRegistryPlants(userRegistryPlantsData)
        }
    }, [userRegistryPlantsIsSuccess]);

    return (
        <Box
            sx={{
                width: "100svw",
                height: "100svh",
            }}
        >
            <NavBar
                barTitle={"Select Establishment"}
                backRoute={"/login"}
                backRouteState={undefined}
            />
            {userRegistryPlantsIsFetching
                ? <Box sx={{height: "90%", width: "90%", display: "flex", alignItems: "center", mx: 2,}}>
                    <LoadingBar sx={{width: "100%"}} loadingLabel={"Fetching your Establishments...."}/>
                </Box>
                : userRegistryPlants?.length
                    ? <Stack sx={{width: "100%", mt: 8}} justifyContent={"center"}>
                        {userRegistryPlants?.map(plant => (
                            <UserRegistryPlantCard
                                key={plant.id}
                                registryUserId={registryUserId as number}
                                registryPlant={plant}
                            />
                        ))}
                    </Stack>
                    : <Box sx={{
                        height: "80%",
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mx: 2,
                    }}>
                        <Typography>This user is not part of any establishments.</Typography>
                    </Box>
            }
        </Box>
    )
}

export default PlantsScreen