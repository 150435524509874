import {IMachineContentRowDetailed} from "../../../../utils/interfaces/IMachineContentRowDetailed";
import {Box, BoxProps as MuiBoxProps, ButtonBase, Collapse, IconButton, Paper, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {RiArrowDropDownFill, RiArrowDropUpFill} from "react-icons/ri";

interface Props extends MuiBoxProps {
    row: IMachineContentRowDetailed
}

const ContentCardDesc = ({row, ...props}: Props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)


    return (
        <Box {...props}
             sx={{
                 p: 0.8,
                 mt: 2,
                 border: "1px solid white",
                 borderRadius: "5px",
                 display: "flex",
                 justifyContent: "flex-start",
                 textAlign: "left",
                 backgroundColor: "transparent"
             }}
             component={Paper}
        >
            <Stack sx={{height: "100%", width: "100%"}} gap={0.5}>
                <Stack
                    component={ButtonBase}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <Typography
                        fontSize={"1.1rem"}
                        fontWeight={"300"}
                        sx={{wordWrap: "break-word"}}
                    >
                        {row.name}
                    </Typography>
                    <IconButton size={"small"}>
                        {isExpanded ? <RiArrowDropUpFill/> : <RiArrowDropDownFill/>}
                    </IconButton>
                </Stack>

                <Collapse in={isExpanded}>
                    <Typography
                        fontSize={"0.9rem"}
                        variant={"subtitle2"}
                        whiteSpace={"pre-line"}
                    >
                        <p>{row.content}</p>
                    </Typography>
                </Collapse>
            </Stack>
        </Box>
    )
}

export default ContentCardDesc