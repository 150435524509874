import {Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import React, {FormEvent, useEffect, useState} from "react";
import {MdClose, MdEdit} from "react-icons/md";
import {IoMdCheckmark} from "react-icons/io";

interface Props {
    currentText: string,
    label?: string
    submitFunction: (payload: { nickname: string }) => void
}

const EditableText = ({currentText, label, submitFunction}: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [newText, setNewText] = useState<string>("")

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            submitFunction({nickname: newText})
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setNewText(currentText)
    }, [currentText]);

    return (
        <Box>
            {isEditing
                ?
                <Stack direction={"row"} alignItems={"center"} gap={0.5} component={"form"} onSubmit={handleFormSubmit}>
                    <TextField
                        value={newText}
                        label={label}
                        onChange={(event) => setNewText(event.target.value)}
                        variant={"standard"}
                        inputProps={{maxLength: 255}}
                    />
                    <IconButton size={"small"} type={"submit"}>
                        <IoMdCheckmark/>
                    </IconButton>
                    <IconButton size={"small"} onClick={() => setIsEditing(false)}>
                        <MdClose/>
                    </IconButton>
                </Stack>
                : <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                    <Typography variant={"h6"}>{currentText}</Typography>
                    <IconButton size={"small"} onClick={() => setIsEditing(true)}>
                        <MdEdit/>
                    </IconButton>
                </Stack>
            }
        </Box>
    )
}

export default EditableText