import {IMachineContentRowDetailed} from "../../../../utils/interfaces/IMachineContentRowDetailed";
import {Box, BoxProps as MuiBoxProps, Link, Stack, Typography} from "@mui/material";
import {RiExternalLinkFill} from "react-icons/ri";

interface Props extends MuiBoxProps {
    row: IMachineContentRowDetailed
}

const ContentCardLink = ({row, ...props}: Props) => {

    return (
        <Box {...props}
             sx={{
                 mt: 2,
                 display: "flex",
                 justifyContent: "flex-start",
                 textAlign: "left"
             }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{height: "100%", width: "100%"}} gap={0.5}
            >
                <Typography
                    component={Link}
                    color={"primary.main"}
                    href={row.content}
                    fontSize={"1.1rem"}
                    fontWeight={"300"}
                    sx={{wordWrap: "break-word"}}
                >
                    {row.name}
                </Typography>
                <Typography
                    component={Link}
                    color={"primary.main"}
                    href={row.content}
                    target="_blank"
                    rel="noopener"
                >
                    <RiExternalLinkFill/>
                </Typography>

            </Stack>
        </Box>
    )
}

export default ContentCardLink