import {
    Box,
    ButtonBase,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack
} from "@mui/material";
import React from "react";
import ListItemLogout from "./ListItemLogout";
import {contentDrawerEntries} from "../utils/content/content-drawerEntries";
import {useNavigate} from "react-router-dom";
import {MdLogout} from "react-icons/md";
import ProjectVersion from "./ProjectVersion";

interface Props {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    userId?: number,
}

const SideDrawer = ({isOpen = false, setIsOpen, userId}: Props) => {
    const navigate = useNavigate();

    const handleNavigation = (to: string) => {
        navigate(to, {state: {userId: userId}})
    }

    return (
        <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
            <Box
                sx={{
                    backgroundColor: "#2b2b2b",
                    height: "100%",
                    display: "flex",
                    minWidth: "18rem",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderColor: "primary.main",
                }}
            >
                <Stack justifyContent="space-between" sx={{width: "100%"}}>
                    <List sx={{mx: 1}} dense={true}>
                        {contentDrawerEntries.map((entryObj, index) => (
                            <React.Fragment key={index}>
                                <ListItem
                                    sx={{my: 1}}
                                    disableGutters={true}
                                    component={ButtonBase}
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            handleNavigation(entryObj.to)
                                        }}
                                    >
                                        <ListItemIcon>
                                            {entryObj.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={entryObj.primaryText}
                                            secondary={entryObj.secondaryText}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Stack>
                <Stack>
                    <ProjectVersion ml={1}/>
                    <ListItemLogout
                        logoutIcon={<MdLogout/>}
                        flipLogoutIcon={true}
                    />
                </Stack>
            </Box>
        </Drawer>
    )
}

export default SideDrawer