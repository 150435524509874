import {Box, BoxProps, ButtonBase, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {IUserRegistryPlant} from "../../utils/interfaces/IUserRegistryPlant";

interface Props extends BoxProps {
    registryUserId: number
    registryPlant: IUserRegistryPlant
}

const UserRegistryPlantCard = ({registryUserId, registryPlant, ...props}: Props) => {
    const routerState = useLocation().state
    const navigate = useNavigate()


    return (
        <Box {...props}
             component={ButtonBase}
             sx={{
                 border: "1px solid white",
                 borderRadius:"5px",
                 p: 2,
                 height: "13rem",
                 minHeight: "13rem",
                 mx: 2,
                 mt: 2,
                 display:"flex",
                 justifyContent: "flex-start",
                 textAlign:"left"
             }}
             onClick={() => navigate("/plantmachines", {
                 state: {
                     userId: routerState.userId,
                     userRegistryId: registryUserId,
                     userRegistryPlantId: registryPlant.id
                 }
             })}
        >
            <Stack sx={{height: "100%",}} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h6"}>{registryPlant.nickname}</Typography>
                    <Typography variant={"subtitle1"} fontWeight={200}>{registryPlant.city}, {registryPlant.address}</Typography>
                </Box>
                <Stack direction={"row"} gap={1}>
                    <Box
                        sx={{
                            backgroundColor: "#fdfdfd",
                            display: "flex",
                            borderRadius: "100%",
                            px: 1,
                            alignItems: "center"
                        }}>
                        <Typography
                            color={"#282828"}
                            fontWeight={"600"}
                            variant={"subtitle2"}
                        >
                            {registryPlant.registry_plant_machines.length}
                        </Typography>
                    </Box>
                    <Typography variant={"subtitle2"}>Machines</Typography>
                </Stack>
            </Stack>
        </Box>
    )
}

export default UserRegistryPlantCard