import {API} from "../../../logics/api_config";
import {useMutation, useQueryClient} from "@tanstack/react-query";

interface ILoginData {
    email: string;
    password: string;
}

interface ILoginResponse {
    id: number
    is_admin: boolean,
    refresh_token: string,
}

async function doPostLogin(data: ILoginData): Promise<ILoginResponse> {
    try {
        const response = await API.post('/login', data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const useDoPostLogin = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: doPostLogin,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["whoAmI"]})
        }
    });
};

export default useDoPostLogin;