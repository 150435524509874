import {API, CustomAxiosError} from "../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IUserRegistryPlantMachineDetailed} from "../../../utils/interfaces/IUserRegistryPlantMachineDetailed";

interface IMutationData {
    userRegistryId: number,
    userRegistryPlantId: number,
    userRegistryPlantMachineId: number,
    payload: any
}

async function doUpdate(data: IMutationData): Promise<IUserRegistryPlantMachineDetailed> {
    try {
        const response = await API.putEncoded(`/app/user_registry/${data.userRegistryId}/plant/${data.userRegistryPlantId}/registry_plant_machine/${data.userRegistryPlantMachineId}`,
            data.payload
        );
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiUpdateUserRegistryPlantMachine = (): UseMutationResult<IUserRegistryPlantMachineDetailed, CustomAxiosError, IMutationData> => {
    const queryClient = useQueryClient();

    return useMutation<IUserRegistryPlantMachineDetailed, CustomAxiosError, IMutationData>({
        mutationFn: doUpdate,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["getUserRegistryPlantMachineDetailed"]}).finally()
        }
    });
};

export default useApiUpdateUserRegistryPlantMachine;
