import {IMachineContentRowDetailed} from "../../../../utils/interfaces/IMachineContentRowDetailed";
import {Box, BoxProps as MuiBoxProps, Stack, Typography} from "@mui/material";

interface Props extends MuiBoxProps {
    row: IMachineContentRowDetailed
}

const ContentCardYt = ({row, ...props}: Props) => {

    return (
        <Box {...props}
             sx={{
                 borderRadius:"5px",
                 height: "15rem",
                 mt: 2,
                 display: "flex",
                 justifyContent: "flex-start",
                 textAlign: "left"
             }}
        >
            <Stack sx={{height: "100%", width: "100%"}} gap={0.5}>
                <Stack sx={{width: "100%"}} direction="row" justifyContent={"space-between"}>
                    <Typography
                        fontSize={"0.9rem"}
                        fontWeight={"300"}
                        sx={{wordWrap: "break-word"}}
                    >
                        {row.name}
                    </Typography>
                    <Typography
                        fontSize={"0.8rem"}
                        variant={"subtitle2"}
                        sx={{wordWrap: "break-word"}}
                    >
                        00:00
                    </Typography>
                </Stack>
                <iframe
                    style={{"border": "none", "borderRadius":"3px"}}
                    height={"100%"}
                    src={`https://www.youtube.com/embed/${row.content.substring(17, 28)}`}
                    title="YouTube video player"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                />
            </Stack>
        </Box>
    )
}

export default ContentCardYt