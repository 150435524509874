import {useMutation} from "@tanstack/react-query";
import {API} from "../../../logics/api_config";

interface AccountResetVerifyResponse{
    reset_token:string
}

async function doPostResetVerify(payload: {verification_code:string}): Promise<AccountResetVerifyResponse> {
    try {
        const response = await API.postEncoded('/user/password/reset/verify', payload);
        return response.data[0];
    } catch (error) {
        throw error;
    }
}

const usePostAccountResetVerify = () => {
    return useMutation({
        mutationFn: doPostResetVerify,
    });
};

export default usePostAccountResetVerify;
