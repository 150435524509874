import {IMachineContentRowDetailed} from "../../../../utils/interfaces/IMachineContentRowDetailed";
import {Box, BoxProps as MuiBoxProps, Link, Stack, Typography} from "@mui/material";
import {TfiDownload} from "react-icons/tfi";

interface Props extends MuiBoxProps {
    row: IMachineContentRowDetailed
}

const ContentCardFile = ({row, ...props}: Props) => {
    const ENDPOINT = process.env.REACT_APP_ENDPOINT;
    // const pdfNameRegex = /(?<=\/\d{3}-)[A-Za-z0-9-]+\d{18}\.pdf(?=$)/g

    return (
        <Box {...props}
             sx={{
                 border: "1px solid white",
                 borderRadius:"5px",
                 p: 0.8,
                 height: "5.9rem",
                 minHeight: "5.9rem",
                 mt: 2,
                 display: "flex",
                 justifyContent: "flex-start",
                 textAlign: "left"
             }}
        >
            <Stack sx={{height: "100%", width: "100%"}} direction={"row"} gap={0.5}>
                <Box
                    component={Link}
                    underline={"none"}
                    href={`${ENDPOINT as string + "/" + row.content}`}
                    target="_blank"
                    rel="noopener"
                    sx={{backgroundColor: "gray", width: "8rem"}}
                >
                    {row.content_type.type === "img"
                        ? <Box
                            sx={{height: "100%", width: "100%"}}
                            component={"img"}
                            src={`${ENDPOINT as string + "/" + row.content}`}
                        />
                        : <Stack
                            sx={{height: "100%", width: "100%", display: "flex"}}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Typography>PDF</Typography>
                            <Typography fontSize={"2rem"}><TfiDownload/></Typography>
                        </Stack>
                    }

                </Box>
                <Stack
                    justifyContent={"space-between"}
                    // sx={{width: "14rem"}} // THIS IS THE ACTUAL SIZE
                    sx={{width: "100%"}}
                >
                    <Typography
                        fontSize={"0.9rem"}
                        fontWeight={"300"}
                        sx={{wordWrap: "break-word"}}
                    >
                        {row.name}
                        {/*{pdfNameRegex.exec(row.content as string)}*/}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"end"}>
                        <Stack>
                            <Typography variant={"subtitle2"}>Type:</Typography>
                            <Typography variant={"subtitle2"}>Size:</Typography>
                        </Stack>
                        <Typography
                            fontSize={"1.1rem"}
                            component={Link}
                            underline={"none"}
                            href={`${ENDPOINT as string + "/" + row.content}`}
                            target="_blank"
                            rel="noopener"
                        >
                            <TfiDownload/>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}

export default ContentCardFile