import {API} from "../../../logics/api_config";
import {useMutation} from "@tanstack/react-query";

async function doPostLogout(): Promise<void> {
    try {
        const response = await API.post('/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
}

const useDoPostLogout = () => {
    return useMutation({mutationFn: doPostLogout});
};

export default useDoPostLogout;