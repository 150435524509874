import {Box, Stack, Typography,} from "@mui/material";
import NavBar from "../../ui/NavBar";
import {useLocation} from "react-router-dom";
import UserRegistryPlantMachineCard from "./UserRegistryPlantMachineCard";
import React, {useEffect, useState} from "react";
import LoadingBar from "../../ui/LoadingBar";
import {IUserRegistryPlantDetailed} from "../../utils/interfaces/IUserRegistryPlantDetailed";
import useApiGetUserRegistryPlantDetailed from "./api-hooks/useApiGetUserRegistryPlantDetailed";
import EditableText from "../../ui/EditableText";
import useApiUpdateRegistryPlant from "./api-hooks/useApiUpdateRegistryPlant";

const UserRegistryPlantMachinesScreen = () => {
    const routerState = useLocation().state
    const [userRegistryPlantDetailed, setUserRegistryPlantDetailed] = useState<IUserRegistryPlantDetailed>()

    const {mutateAsync: updateRegistryPlant} = useApiUpdateRegistryPlant()

    const {
        data: userRegistryPlantDetailedData,
        error: userRegistryPlantDetailedError,
        isFetching: userRegistryPlantDetailedIsFetching,
    } = useApiGetUserRegistryPlantDetailed(routerState.userRegistryId, routerState.userRegistryPlantId)
    if (userRegistryPlantDetailedError) {
        console.log(userRegistryPlantDetailedError)
    }

    const handleUpdateRegistryPlant = async (payload: { nickname: string }) => {
        try {
            const response = updateRegistryPlant({
                userRegistryId: routerState.userRegistryId,
                userRegistryPlantId: routerState.userRegistryPlantId,
                payload: payload
            })
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (userRegistryPlantDetailedData) {
            setUserRegistryPlantDetailed(userRegistryPlantDetailedData)
        }
    }, [userRegistryPlantDetailedData]);


    return (
        <Box
            sx={{
                width: "100svw",
                height: "100svh",
            }}
        >
            <NavBar
                barTitle={"Select Machine"}
                backRoute={"/plants"}
                backRouteState={{
                    userId: routerState.userId,
                    userRegistryId: routerState.registryUserId,
                    userRegistryPlantId: routerState.userRegistryPlantId,
                    userRegistryPlantMachineId: undefined
                }}
            />
            {userRegistryPlantDetailedIsFetching
                ? <Box sx={{height: "90%", width: "90%", display: "flex", alignItems: "center", mx: 2,}}>
                    <LoadingBar sx={{width: "100%"}} loadingLabel={"Fetching machines...."}/>
                </Box>
                : userRegistryPlantDetailed && !userRegistryPlantDetailedIsFetching &&
                <>
                    <Box sx={{mt: 10, mx: 2, mb: 4}}>
                        <EditableText
                            currentText={userRegistryPlantDetailed.nickname}
                            label={"Establishment name"}
                            submitFunction={handleUpdateRegistryPlant}
                        />
                        <Typography variant={"subtitle1"} fontWeight={200}>
                            {userRegistryPlantDetailed.city}, {userRegistryPlantDetailed.address}
                        </Typography>
                    </Box>
                    {userRegistryPlantDetailed.registry_plant_machines.length
                        ? <Stack sx={{width: "100%"}} justifyContent={"center"}>
                            {userRegistryPlantDetailed.registry_plant_machines.map(machine => (
                                <UserRegistryPlantMachineCard key={machine.id} userRegistryPlantMachine={machine} plantName={userRegistryPlantDetailed.nickname}/>
                            ))}
                        </Stack>
                        : <Box sx={{
                            height: "80%",
                            width: "90%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mx: 2,
                        }}>
                            <Typography>
                                No machines were found for this establishment
                            </Typography>
                        </Box>
                    }
                </>
            }
        </Box>
    )
}

export default UserRegistryPlantMachinesScreen;