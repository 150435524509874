import {Box, Stack, Typography} from "@mui/material";
import {MdLocationOn} from "react-icons/md";
import EditableText from "../../ui/EditableText";
import {IUserRegistryPlantMachineDetailed} from "../../utils/interfaces/IUserRegistryPlantMachineDetailed";
import useApiUpdateRegistryPlantMachine from "./api-hooks/useApiUpdateRegistryPlantMachine";
import {useLocation} from "react-router-dom";
import RegistryPlantMachineInfoDesc from "./RegistryPlantMachineInfoDesc";

interface Props {
    userRegistryPlantMachineDetailed: IUserRegistryPlantMachineDetailed
}

const RegistryPlantMachineInfo = ({userRegistryPlantMachineDetailed}: Props) => {
    const routerState = useLocation().state
    const ENDPOINT = process.env.REACT_APP_ENDPOINT;

    const {mutateAsync: updateRegistryPlantMachine} = useApiUpdateRegistryPlantMachine()

    const handleUpdateRegistryPlantMachine = async (payload: { nickname: string }) => {
        try {
            const response = updateRegistryPlantMachine({
                userRegistryId: routerState.userRegistryId,
                userRegistryPlantId: routerState.userRegistryPlantId,
                userRegistryPlantMachineId: routerState.userRegistryPlantMachineId,
                payload: payload
            })
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Stack sx={{mx: 2}}>
            <Stack sx={{my: 2}} direction={"row"} alignItems={"center"} gap={0.5} color={"white"}>
                <MdLocationOn/>
                <Typography variant={"subtitle1"}>{routerState.plantName}</Typography>
            </Stack>
            <Stack>
                <EditableText
                    currentText={userRegistryPlantMachineDetailed.nickname
                        ? userRegistryPlantMachineDetailed.nickname
                        : userRegistryPlantMachineDetailed.machine.name
                    }
                    label={"Machine name"}
                    submitFunction={handleUpdateRegistryPlantMachine}
                />
                <Typography variant={"subtitle1"} fontWeight={200}>
                    {userRegistryPlantMachineDetailed.machine.name}
                </Typography>
                <Typography variant={"caption"} fontWeight={200}>
                    {userRegistryPlantMachineDetailed.serial_number}
                </Typography>
            </Stack>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: "12rem",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Box
                    sx={{maxWidth:"95%", maxHeight: "11rem", borderRadius: "10px", mt: 20}}
                    component={"img"}
                    src={`${ENDPOINT as string + "/" + userRegistryPlantMachineDetailed.machine.image_url}`}
                    alt={""}
                />
                <Box sx={{overflowY: "clip"}}>
                    <Box
                        sx={{
                            maxWidth:"95%",
                            maxHeight: "11rem",
                            borderRadius: "10px",
                            transform: "rotatex(180deg) translatey(0px)",
                            maskImage: "linear-gradient(transparent 90%, white 100%)",
                        }}
                        component={"img"}
                        src={`${ENDPOINT as string + "/" + userRegistryPlantMachineDetailed.machine.image_url}`}
                        alt={""}
                    />
                </Box>
            </Box>
            <RegistryPlantMachineInfoDesc description={userRegistryPlantMachineDetailed.description}/>
        </Stack>
    )
}

export default RegistryPlantMachineInfo
