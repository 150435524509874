import axios, {AxiosInstance, AxiosError, AxiosResponse} from 'axios';
import response_interceptor from "../interceptors/axios_response_interceptor";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
console.log("Endpoint: ", ENDPOINT);

async function get(url: string, params?: any) {
    try {
        const instance = await getInstance();
        if (params) {
            return await instance.get(ENDPOINT + url, {params: {...params}});
        }
        return await instance.get(ENDPOINT + url);
    } catch (error) {
        throw error;
    }
}

async function post(url: string, params?: any) {
    try {
        const instance = await getInstance();

        return await instance.post(ENDPOINT + url, {...params});
    } catch (error) {
        throw error;
    }
}

async function postEncoded(url: string, params?: any) {
    try {
        const instance = await getInstance();
        return await instance.post(ENDPOINT + url, new URLSearchParams({...params}));
    } catch (error) {
        throw error;
    }
}

async function put(url: string, data?: any) {
    try {
        const instance = await getInstance();
        return await instance.put(ENDPOINT + url, {...data});
    } catch (error) {
        throw error;
    }
}

async function putEncoded(url: string, data?: any) {
    try {
        const instance = await getInstance();
        return await instance.put(ENDPOINT + url, new URLSearchParams({...data}));
    } catch (error) {
        throw error;
    }
}

async function DELETE(url: string) {
    try {
        const instance = await getInstance();
        return await instance.delete(ENDPOINT + url);
    } catch (error) {
        throw error;
    }
}

async function getInstance() {
    // let access_token = await localStorage.getItem('@access_token');
    let instance = axios.create({
        baseURL: ENDPOINT,
        // headers: {
        //     'Authorization': `Bearer ${access_token}`
        // }

    })
    response_interceptor(instance)
    return instance
}

export const API = {
    get,
    post,
    postEncoded,
    put,
    putEncoded,
    DELETE,
}

export interface CustomAxiosError extends AxiosError {

}