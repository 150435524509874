import React, {FormEvent, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../utils/contexts/auth-context";
import usePostLogin from "./api-hooks/usePostLogin";
import {Box, Button, IconButton, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import LoadingBar from "../../ui/LoadingBar";
import {GoEye, GoEyeClosed} from "react-icons/go";
import {ILoginFormControl} from "./LoginScreen";

const landingRoute = "/plants"

interface Props extends ILoginFormControl {
}

const LoginForm = ({isLoading, setIsLoading, setFormMsg, handleStopReset, handleStartReset}: Props) => {
    const navigate = useNavigate();
    const {setIsAuthenticated} = useAuth();

    const [showPassword, setShowPassword] = useState(false)

    const {mutateAsync: loginMutation} = usePostLogin();

    const handleLoginSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormMsg(undefined)
        setIsLoading(true)
        try {
            const response = await loginMutation({
                email: e.currentTarget.email.value,
                password: e.currentTarget.password.value,
            });
            setIsAuthenticated(true);
            navigate(landingRoute, {state: {userId: response.id}})
        } catch (error) {
            setFormMsg({type: "error", message: "An error occurred logging you in."})
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Stack
            sx={{alignItems: "start", gap: 2.5,}}
            component="form"
            onSubmit={handleLoginSubmit}
        >
            <Typography variant={"h5"}>
                Login
            </Typography>
            <Stack sx={{width: "100%", gap: 2}}>
                <TextField
                    label={"E-mail"}
                    type={"email"}
                    size={"small"}
                    name="email"
                    required={true}
                    fullWidth={true}
                />
                <TextField
                    label="Password"
                    size={"small"}
                    name="password"
                    required={true}
                    type={showPassword ? "text" : "password"}
                    fullWidth={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <GoEyeClosed/> : <GoEye/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Box sx={{height: "2rem"}}>
                    {isLoading
                        ? <LoadingBar loadingLabel={"Logging you in..."}/>
                        : <Button
                            fullWidth={true}
                            type="submit"
                            variant="contained"
                        >
                            <Typography variant={"button"}>
                                Login
                            </Typography>
                        </Button>
                    }
                </Box>
            </Stack>
            <Button
                variant={"text"}
                fullWidth={true}
                onClick={handleStartReset}
            >
                <Typography
                    sx={{
                        color: "primary.main",
                        fontWeight: "600",
                    }}
                    variant={"subtitle2"}
                >
                    Forgot your password?
                </Typography>
            </Button>
        </Stack>
    );
};

export default LoginForm;
