import React, {FormEvent, useState} from 'react';
import {
    Box,
    Button,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import usePostAccountResetEmail from "./api-hooks/usePostAccountResetEmail";
import LoadingBar from "../../ui/LoadingBar";
import {ILoginFormControl} from "./LoginScreen";

interface Props extends ILoginFormControl {
    setVerificationCode: (code: string) => void
}

const LoginResetEmailForm = ({
                                 isLoading,
                                 setIsLoading,
                                 setFormMsg,
                                 handleStopReset,
                                 handleStartReset,
                                 setVerificationCode
                             }: Props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("")

    const {mutateAsync: accountResetEmail} = usePostAccountResetEmail()

    const handleEmailSubmit = async (e: FormEvent<HTMLFormElement>) => {
        setFormMsg(undefined)
        e.preventDefault()
        setIsLoading(true)
        try {
            const response = await accountResetEmail({email: email})
            if (response.status === "ko") {
                setFormMsg({type: "error", message: "An error occurred submitting your email."})
                return
            }
            setVerificationCode(response.verification_code)
            setFormMsg({type: "success", message: "Email successfully sent!"})

        } catch (error) {
            setFormMsg({type: "error", message: "An error occurred submitting your email."})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Stack
            sx={{alignItems: "start", gap: 2.5,}}
            component={"form"}
            onSubmit={handleEmailSubmit}
        >
            <Box>
                <Typography variant={"h5"}>
                    Forgot Your Password?
                </Typography>
                <Typography variant={"subtitle2"}>
                    Enter your e-mail address. We'll send reset instructions.
                </Typography>
            </Box>
            <Stack sx={{width: "100%", gap: 2}}>
                <TextField
                    value={email}
                    label={"E-mail"}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}
                    type={"email"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={isLoading}
                />
                <Box sx={{height: "2rem"}}>
                    {isLoading
                        ? <LoadingBar loadingLabel={"Submitting..."}/>
                        : <Button
                            type={"submit"}
                            variant="contained"
                            disabled={isLoading}
                            fullWidth={true}
                        >
                            Submit
                        </Button>
                    }
                </Box>
            </Stack>
            <Button
                variant={"text"}
                fullWidth={true}
                onClick={handleStopReset}
            >
                <Typography
                    sx={{
                        color: "primary.main",
                        fontWeight: "600",
                    }}
                    variant={"subtitle2"}
                >
                    Back to login
                </Typography>
            </Button>
        </Stack>
    );
};

export default LoginResetEmailForm;
