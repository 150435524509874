import {API, CustomAxiosError} from "../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IUserRegistryPlant} from "../../../utils/interfaces/IUserRegistryPlant";

async function doGet(registryUserId:number | undefined): Promise<IUserRegistryPlant[]> {
    try {
        const response = await API.get(`/app/user_registry/${registryUserId}/plant`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetUserRegistryPlants = (registryUserId:number | undefined): UseQueryResult<IUserRegistryPlant[], CustomAxiosError> => {
    return useQuery<IUserRegistryPlant[], CustomAxiosError>({
        queryKey: ["getUserRegistryPlants"],
        queryFn: () => doGet(registryUserId),
        enabled: !!registryUserId
        });
};

export default useApiGetUserRegistryPlants;
