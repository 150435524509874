import {Box, BoxProps, LinearProgress, Typography} from "@mui/material";
import React from "react";

interface Props extends BoxProps{
    loadingLabel?: string
}

const LoadingBar = ({loadingLabel = "Loading...", ...props}:Props) => {
    return (
        <Box {...props}>
            <Typography>{loadingLabel}</Typography>
            <LinearProgress/>
        </Box>
    )
}

export default LoadingBar
