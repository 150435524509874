import {useMutation} from "@tanstack/react-query";
import {API} from "../../../logics/api_config";


async function doPostResetPassword(payload: {new_password:string, reset_token:string}): Promise<Object> {
    try {
        const response = await API.postEncoded('/user/password/reset/change', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const usePostAccountResetPassword = () => {
    return useMutation({
        mutationFn: doPostResetPassword,
    });
};

export default usePostAccountResetPassword;
