import {Box, BoxProps, ButtonBase, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {IUserRegistryPlantMachineDetailed} from "../../utils/interfaces/IUserRegistryPlantMachineDetailed";

interface Props extends BoxProps {
    plantName: string,
    userRegistryPlantMachine: IUserRegistryPlantMachineDetailed
}

const UserRegistryPlantMachineCard = ({userRegistryPlantMachine, plantName, ...props}: Props) => {
    const navigate = useNavigate()
    const routerState = useLocation().state

    const ENDPOINT = process.env.REACT_APP_ENDPOINT;

    return (
        <Box {...props}
             component={ButtonBase}
             sx={{
                 border: "1px solid white",
                 borderRadius: "5px",
                 px: 2,
                 pt: 1,
                 pb: 3,
                 minHeight: "7rem",
                 mx: 2,
                 mt: 2,
                 display: "flex",
                 justifyContent: "flex-start",
                 textAlign: "left",
                 overflowY:"clip",
             }}
             onClick={() => navigate("/plantmachinecontents", {
                 state: {
                     userId: routerState.userId,
                     userRegistryId: routerState.userRegistryId,
                     userRegistryPlantId: routerState.userRegistryPlantId,
                     plantName: plantName,
                     userRegistryPlantMachineId: userRegistryPlantMachine.id,
                 }
             })}
        >
            <Stack
                sx={{height: "100%", width: "100%"}}
                justifyContent={"space-between"}
                alignItems="center"
                direction={"row"}
            >
                <Box sx={{
                    width: "65%",
                    wordWrap: "break-word",
                    overflowX: "hidden",
                }}>
                    <Typography variant={"h6"}>
                        {userRegistryPlantMachine.nickname
                            ? userRegistryPlantMachine.nickname
                            : userRegistryPlantMachine.machine.name
                        }
                    </Typography>
                    <Typography variant={"subtitle2"} fontWeight={200}>{userRegistryPlantMachine.machine.name}</Typography>
                    <Typography variant={"caption"} fontWeight={200}>{userRegistryPlantMachine.serial_number}</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection:"column",
                    height: "5rem",
                    width: "40%",
                    justifyContent: "flex-start",
                }}>
                    <Box
                        sx={{
                          maxHeight: "6.5rem", maxWidth: "11.5rem", borderRadius: "10px",
                        }}
                        component={"img"}
                        src={`${ENDPOINT as string + "/" + userRegistryPlantMachine.machine.image_url}`}
                        alt={""}
                    />
                    <Box
                        sx={{
                            transform: "rotatex(180deg) translatey(-2px)",
                            maskImage: "linear-gradient(transparent 50%, white 120%)",
                            backgroundClip: "text",
                            color: "transparent",
                            borderRadius: "10px"
                        }}
                        component={"img"}
                        src={`${ENDPOINT as string + "/" + userRegistryPlantMachine.machine.image_url}`}
                        alt={"CHANGE THIS"}
                    />
                </Box>
            </Stack>
        </Box>
    )
}

export default UserRegistryPlantMachineCard