import React, {ReactNode} from "react";
import {MdHome, MdOutlineFolderDelete, MdQuestionMark} from "react-icons/md";

interface DrawerEntries{
    primaryText: string,
    secondaryText?: string,
    to: string,
    icon: ReactNode,
}

export const contentDrawerEntries:readonly DrawerEntries[] = [
    {
        primaryText: "Home",
        secondaryText:"Your establishments",
        to: "/plants",
        icon:<MdHome/>,
    },
    {
        primaryText: "Request assistance",
        to: "/",
        icon:<MdQuestionMark/>
    },
    {
        primaryText: "Erase user data",
        to: "/",
        icon:<MdOutlineFolderDelete/>
    },
];

