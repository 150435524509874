import {Box, Stack, Typography} from "@mui/material";

const ContentDetailsScreen = () => {
    return (
        <>
            <Stack>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Blades
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                ON
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>

                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Sharpening
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                ON
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>

                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Mode
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                Auto (P.2)
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>

                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Daily consumption
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                5.3
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                kW
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>

            <Typography variant={"h6"} sx={{mt: 2}}>Values</Typography>
            <Stack>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Thickness
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                1.25
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                mm
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Speed (1)
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                20
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                m/min
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Speed (2)
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                15
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                m/min
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>

            <Typography variant={"h6"} sx={{mt: 2}}>Sharpening</Typography>
            <Stack>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Break time
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                20
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                s
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Intensity
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                10
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                1/100 s
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Speed
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                7.5
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                m/min
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{borderBottom: "1px solid", borderColor: "#fdfdfd"}}>
                    <Stack direction={"row"} sx={{py: 0.8}}>
                        <Typography variant={"subtitle1"} fontWeight={200} sx={{width: "70%"}}>
                            Grinding detach
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "30%"}}>
                            <Typography variant={"subtitle1"} fontWeight={200}>
                                OFF
                            </Typography>
                            <Typography variant={"subtitle1"} fontWeight={200}>

                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}

export default ContentDetailsScreen;