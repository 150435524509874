import {AppBar, AppBarProps, IconButton, Stack, Typography} from "@mui/material"
import {useNavigate} from "react-router-dom";
import {TfiArrowLeft, TfiMenu} from "react-icons/tfi";
import {useState} from "react";
import SideDrawer from "./SideDrawer";
import {MdHome} from "react-icons/md";

interface Props extends AppBarProps {
    barTitle: string,
    backRoute: string,
    backRouteState: {
        userId: number,
        userRegistryId?: number,
        userRegistryPlantId?: number,
        userRegistryPlantMachineId?: number,
    } | undefined,
}

const NavBar = ({barTitle, backRoute, backRouteState, ...props}: Props) => {
    const navigate = useNavigate()
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

    return (
        <AppBar
            {...props}
            sx={{py: 1, height: "7%"}}
        >
            <Stack direction={"row"} justifyContent={"space-between"} sx={{mx: 1}}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                    sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}
                >
                    {backRouteState
                        ? <IconButton onClick={() => navigate(backRoute, {state: backRouteState})}>
                            <TfiArrowLeft/>
                        </IconButton>
                        : <IconButton> <MdHome/> </IconButton>
                    }
                    <Typography
                        variant="h6"
                        sx={{
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            height: "2rem",
                        }}>
                        {barTitle}
                    </Typography>
                </Stack>
                <IconButton onClick={() => setIsDrawerOpen(true)}>
                    <TfiMenu/>
                </IconButton>
            </Stack>
            <SideDrawer
                isOpen={isDrawerOpen}
                setIsOpen={setIsDrawerOpen}
                userId={backRouteState?.userId}
            />
        </AppBar>
    )
}

export default NavBar