import React, {FormEvent, useState} from 'react';
import {
    Box,
    Button,
    IconButton,
    InputAdornment, Stack,
    TextField,
    Typography
} from '@mui/material';
import {GoEye, GoEyeClosed} from "react-icons/go";
import usePostAccountResetPassword from "./api-hooks/usePostAccountResetPassword";
import {ILoginFormControl} from "./LoginScreen";
import LoadingBar from "../../ui/LoadingBar";

interface Props extends ILoginFormControl {
    resetToken: string,
}

const LoginResetPasswordForm = ({
                                    isLoading,
                                    resetToken,
                                    setFormMsg,
                                    setIsLoading,
                                    handleStopReset,
                                    handleStartReset
                                }: Props) => {

    const [showPasswords, setShowPasswords] = useState([false, false])
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordConfirmed, setNewPasswordConfirmed] = useState<string>("")

    const {mutateAsync: accountResetPassword} = usePostAccountResetPassword()

    const isValidPassword = (password: string): boolean => {
        if (password.length < 8) return false;

        let hasUpperCase = false;
        let hasLowerCase = false;
        let hasNumber = false;
        let hasSpecialChar = false;
        const specialCharacters = '!@#$%^&*(),.?\\":{}|<>';

        for (const char of password) {
            if (char >= 'A' && char <= 'Z') hasUpperCase = true;
            else if (char >= 'a' && char <= 'z') hasLowerCase = true;
            else if (char >= '0' && char <= '9') hasNumber = true;
            else if (specialCharacters.includes(char)) hasSpecialChar = true;

            if (hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar) return true;
        }

        return false;
    };

    const handleNewPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        setFormMsg(undefined)
        try {
            if (!isValidPassword(newPassword)) {
                setFormMsg({type: "error", message: "Password doesn't meet the criteria!"})
                return
            }
            if (newPassword !== newPasswordConfirmed) {
                setFormMsg({type: "error", message: "Passwords must match!"})
                return
            }
            await accountResetPassword({new_password: newPassword, reset_token: resetToken})
            handleStopReset()
            setFormMsg({type: "success", message: "Password successfully reset!"})
        } catch (error) {
            setFormMsg({type: "error", message: "An error occurred changing the password."})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Stack
            sx={{alignItems: "start", gap: 2.5,}}
            component={"form"}
            onSubmit={handleNewPasswordSubmit}
        >
            <Box>
                <Typography variant={"h5"}>
                    Reset Password
                </Typography>
                <Typography variant={"subtitle2"}>
                    Choose a new password that's at least 8 characters long and includes:
                </Typography>
                <Typography variant={"subtitle2"}>
                    - An uppercase and a lowercase letter
                </Typography>
                <Typography variant={"subtitle2"}>
                    - A number
                </Typography>
                <Typography variant={"subtitle2"}>
                    - A special character
                </Typography>
            </Box>
            <Stack sx={{width: "100%", gap: 2}}>
                <TextField
                    value={newPassword}
                    label={"Password"}
                    onChange={(event) => {
                        setNewPassword(event.target.value)
                    }}
                    type={showPasswords[0] ? "text" : "password"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={isLoading}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPasswords([!showPasswords[0], showPasswords[1]])}
                                    tabIndex={-1}
                                >
                                    {showPasswords[0] ? <GoEyeClosed/> : <GoEye/>}
                                </IconButton>
                            </InputAdornment>
                        )

                    }}
                />
                <TextField
                    value={newPasswordConfirmed}
                    label={"Confirm Password"}
                    onChange={(event) => {
                        setNewPasswordConfirmed(event.target.value)
                    }}
                    type={showPasswords[1] ? "text" : "password"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={isLoading}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPasswords([showPasswords[0], !showPasswords[1]])}
                                    tabIndex={-1}
                                >
                                    {showPasswords[1] ? <GoEyeClosed/> : <GoEye/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Box sx={{height: "2rem"}}>
                    {isLoading
                        ? <LoadingBar loadingLabel={"Submitting..."}/>
                        : <Button
                            type={"submit"}
                            variant="contained"
                            disabled={isLoading}
                            fullWidth={true}
                        >
                            Reset password
                        </Button>
                    }
                </Box>
            </Stack>
            <Button
                variant={"text"}
                fullWidth={true}
                onClick={handleStopReset}
            >
                <Typography
                    sx={{
                        color: "primary.main",
                        fontWeight: "600",
                    }}
                    variant={"subtitle2"}
                >
                    Back to login
                </Typography>
            </Button>
        </Stack>
    );
};

export default LoginResetPasswordForm;
