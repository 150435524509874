import {useLocation} from "react-router-dom";
import {Box, Paper, Stack, Tab, Tabs, Typography} from "@mui/material";
import NavBar from "../../ui/NavBar";
import RegistryPlantMachineInfo from "./RegistryPlantMachineInfo";
import React, {Fragment, ReactElement, useEffect, useState} from "react";
import {IUserRegistryPlantMachineDetailed} from "../../utils/interfaces/IUserRegistryPlantMachineDetailed";
import useApiGetUserRegistryPlantMachineDetailed from "./api-hooks/useApiGetUserRegistryPlantMachineDetailed";
import LoadingBar from "../../ui/LoadingBar";
import ContentCardPicker from "./ContentComponents/ContentCardComponents/ContentCardPicker";
import {CategoryName} from "../../utils/interfaces/IContentCategory";
import {IMachineContentRowDetailed} from "../../utils/interfaces/IMachineContentRowDetailed";
import ContentDetailsScreen from "./ContentComponents/ContentDetailsScreen";

const TabNames = ["Documents", "Tutorials", "Spare parts", "Details", "Consumption", "Alarms"]

const RegistryPlantMachineContentScreen = () => {
    const routerState = useLocation().state
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const [userRegistryPlantMachineDetailed, setUserRegistryPlantMachineDetail] = useState<IUserRegistryPlantMachineDetailed>()

    const titledTypedRowsObj: Record<string, Record<CategoryName, IMachineContentRowDetailed[]>> = userRegistryPlantMachineDetailed?.machine.machine_contents.reduce(
        (acc, content) => ({
            ...acc,
            [content.name]: {[content.category.name]: content.machine_content_rows}
        }), {} as any)

    const {
        data: userRegistryPlantMachineDetailedData,
        error: userRegistryPlantMachineDetailedError,
        isFetching: userRegistryPlantMachineDetailedIsFetching
    } = useApiGetUserRegistryPlantMachineDetailed(
        routerState.userRegistryId,
        routerState.userRegistryPlantId,
        routerState.userRegistryPlantMachineId
    )
    if (userRegistryPlantMachineDetailedError) {
        console.log(userRegistryPlantMachineDetailedError)
    }

    useEffect(() => {
        if (userRegistryPlantMachineDetailedData) {
            setUserRegistryPlantMachineDetail(userRegistryPlantMachineDetailedData)
        }
    }, [userRegistryPlantMachineDetailedData]);

    return (
        <Box
            sx={{
                width: "100svw",
                height: "100svh",
            }}
        >
            <NavBar
                barTitle={userRegistryPlantMachineDetailed && !userRegistryPlantMachineDetailedIsFetching
                    ? userRegistryPlantMachineDetailed.nickname
                        ? userRegistryPlantMachineDetailed.nickname
                        : userRegistryPlantMachineDetailed.machine.name
                    : ""
                }
                backRoute={"/plantmachines"}
                backRouteState={{
                    userId: routerState.userId,
                    userRegistryId: routerState.userRegistryId,
                    userRegistryPlantId: routerState.userRegistryPlantId,
                    userRegistryPlantMachineId: undefined,
                }}
            />
            {userRegistryPlantMachineDetailedIsFetching
                ? <Box sx={{height: "90%", width: "90%", display: "flex", alignItems: "center", mx: 2,}}>
                    <LoadingBar sx={{width: "100%"}} loadingLabel={"Fetching content..."}/>
                </Box>
                : userRegistryPlantMachineDetailed &&
                <>
                    <Stack sx={{width: "100%", mt: 8}} justifyContent={"center"}>
                        <RegistryPlantMachineInfo userRegistryPlantMachineDetailed={userRegistryPlantMachineDetailed}/>
                        <Tabs
                            value={selectedTab}
                            onChange={(e, value) => setSelectedTab(value)}
                            TabIndicatorProps={{sx: {display: 'none'}}}
                            sx={{
                                '& .MuiTabs-flexContainer': {
                                    flexWrap: 'wrap',
                                },
                            }}
                        >
                            {TabNames.map((tabName, index) => (
                                <Tab
                                    key={index}
                                    label={tabName}
                                    disabled={tabName === "Consumption" || tabName === "Alarms"}
                                    sx={{
                                        color: "#fdfdfd",
                                        "& .MuiDisabled": {
                                            color: "red"
                                        },
                                        width: "33%",
                                        borderBottom: selectedTab === index ? "3px solid" : "1px solid",
                                        borderColor: selectedTab === index ? "primary.main" : "#fdfdfd",
                                    }}
                                />
                            ))}
                        </Tabs>
                        {(() => {
                            let titledTypedRowElementsObj: Record<string, Record<CategoryName, ReactElement[]>> = {} as any

                            for (const title in titledTypedRowsObj) {
                                for (const categoryName in titledTypedRowsObj[title]) {
                                    const contentRowsArray = titledTypedRowsObj[title][categoryName as CategoryName]

                                    titledTypedRowElementsObj[title] = {} as any

                                    titledTypedRowElementsObj[title as string][categoryName as CategoryName] = contentRowsArray.map(
                                        (row, i) => <ContentCardPicker row={row} key={i}/>
                                    )
                                }
                            }

                            return (
                                <>
                                    {TabNames.map((tabName, index) => {
                                        const categoryNames: CategoryName[] = ["documents", "tutorials", "spare_parts"]

                                        return (
                                            <Fragment key={index}>
                                                {index === selectedTab &&
                                                    <Stack
                                                        sx={{width: "contentRowElementsArray%", mx: 1.5, mt: 1, mb: 2}}
                                                        hidden={index !== selectedTab}
                                                        justifySelf={"center"}
                                                    >
                                                        {/*<Typography variant={"h6"}>{tabName}</Typography>*/}
                                                        <Stack>
                                                            {tabName === "Details"
                                                                ? <ContentDetailsScreen/>
                                                                : <>
                                                                    {Object.keys(titledTypedRowElementsObj).map((title) => {
                                                                        return (
                                                                            <>
                                                                                {titledTypedRowElementsObj[title][categoryNames[index]]
                                                                                    ? <Paper
                                                                                        sx={{
                                                                                            backgroundColor: "#2f2f2f",
                                                                                            borderTop: "1px solid #FBFBFBFF",
                                                                                            borderTopRightRadius: 0,
                                                                                            borderTopLeftRadius: 0,
                                                                                            borderBottomLeftRadius: "5px",
                                                                                            borderBottomRightRadius: "5px",
                                                                                            mt: 2,
                                                                                            pb: 1,
                                                                                            px: 1,
                                                                                        }}
                                                                                        elevation={2}
                                                                                    >
                                                                                        <Typography variant={"h6"}
                                                                                                    fontWeight={200}
                                                                                                    sx={{mt: 0.5}}>
                                                                                            {"-" + title}
                                                                                        </Typography>
                                                                                        {
                                                                                            Object.keys(titledTypedRowElementsObj[title]).map(() => {
                                                                                                return (titledTypedRowElementsObj[title][categoryNames[index]]
                                                                                                        ? titledTypedRowElementsObj[title][categoryNames[index]].map(
                                                                                                            (rowElement) => rowElement
                                                                                                        )
                                                                                                        : ""
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Paper>
                                                                                    : ""
                                                                                }
                                                                            </>
                                                                        )
                                                                    })}
                                                                </>
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                }
                                            </Fragment>
                                        )
                                    })}
                                </>
                            )
                        })()}
                    </Stack>
                </>
            }
        </Box>
    )
}

export default RegistryPlantMachineContentScreen;
