import {API, CustomAxiosError} from "../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IUserRegistry} from "../../../utils/interfaces/IUserRegistry";

async function doGet(userId:number): Promise<IUserRegistry[]> {
    try {
        const response = await API.get(`/app/user/${userId}/registries`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetUserRegistries = (userId:number): UseQueryResult<IUserRegistry[], CustomAxiosError> => {
    return useQuery<IUserRegistry[], CustomAxiosError>({
        queryKey: ["getUserRegistries"],
        queryFn: () => doGet(userId)
        });
};

export default useApiGetUserRegistries;
