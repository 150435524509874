import {API, CustomAxiosError} from "../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IUserRegistryPlantMachineDetailed} from "../../../utils/interfaces/IUserRegistryPlantMachineDetailed";

async function doGet(
    userRegistryId: number,
    userRegistryPlantId: number,
    userRegistryPlantMachineId: number | undefined
): Promise<IUserRegistryPlantMachineDetailed> {
    try {
        const response = await API.get(`/app/user_registry/${userRegistryId}/plant/${userRegistryPlantId}/registry_plant_machine/${userRegistryPlantMachineId}`);
        return response.data.data[0];
    } catch (error) {
        throw error;
    }
}

const useApiGetUserRegistryPlantMachineDetailed = (
    userRegistryId: number,
    userRegistryPlantId: number,
    userRegistryPlantMachineId: number | undefined
): UseQueryResult<IUserRegistryPlantMachineDetailed, CustomAxiosError> => {
    return useQuery<IUserRegistryPlantMachineDetailed, CustomAxiosError>({
        queryKey: ["getUserRegistryPlantMachineDetailed"],
        queryFn: () => doGet(userRegistryId, userRegistryPlantId, userRegistryPlantMachineId),
        enabled: !!userRegistryPlantMachineId
    });
};

export default useApiGetUserRegistryPlantMachineDetailed;
