import React from 'react';
import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import LoginScreen from "../components/Login/LoginScreen";
import PlantsScreen from "../components/UserRegistryPlants/UserRegistryPlantsScreen";
import UserRegistryPlantMachinesScreen from "../components/UserRegistryPlantMachines/UserRegistryPlantMachinesScreen";
import RegistryPlantMachineContentScreen
    from "../components/RegistryPlantMachineContent/RegistryPlantMachineContentScreen";

const Navigation = () => {

    return (
        <Routes>
            <Route path="/*" index element={<LoginScreen/>}/>
            <Route path="/plants" element={
                <ProtectedRoute>
                    <PlantsScreen/>
                </ProtectedRoute>
            }/>
            <Route path="/plantmachines" element={
                <ProtectedRoute>
                    <UserRegistryPlantMachinesScreen/>
                </ProtectedRoute>
            }/>
            <Route path="/plantmachinecontents" element={
                <ProtectedRoute>
                    <RegistryPlantMachineContentScreen/>
                </ProtectedRoute>
            }/>
        </Routes>
    );
};

export default Navigation;
