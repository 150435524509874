import React, {useState} from 'react';
import LoginForm from "./LoginForm";
import {Box, Slide, Stack, Typography} from '@mui/material';
import LoginResetEmailForm from "./LoginResetEmailForm";
import LoginResetVerifyForm from "./LoginResetVerifyForm";
import LoginResetPasswordForm from "./LoginResetPasswordForm";

interface IFormControl {
    isLoading: boolean,
    setIsLoading: (isLoading: boolean) => void
    setFormMsg: (msg: IFormMessage | undefined) => void
}

export interface ILoginFormControl extends IFormControl {
    handleStartReset: () => void
    handleStopReset: () => void
}

export interface IFormMessage {
    type: "error" | "success",
    message: string,
}

const LoginScreen = () => {
    const slideContainerRef = React.useRef<HTMLElement>(null);
    const [formMsg, setFormMsg] = useState<IFormMessage | undefined>(undefined)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isResetting, setIsResetting] = useState<boolean>(false)
    const [verificationCode, setVerificationCode] = useState<string>("")
    const [resetToken, setResetToken] = useState<string>("")

    const handleStartReset = () => {
        setIsResetting(true)
        setFormMsg(undefined)
        setVerificationCode("")
        setResetToken("")
    }

    const handleStopReset = () => {
        setIsResetting(false)
        setFormMsg(undefined)
        setVerificationCode("")
        setResetToken("")
    }

    return (
        <Box
            sx={{
                width: "100svw",
                height: "100svh",
            }}
        >
            <Stack sx={{justifyContent: "space-around", alignItems: "center", height: "100%", width: "100%"}}>
                <Box sx={{mt: 2}}>
                    <Box
                        component={"img"}
                        sx={{
                            display: "inline-block",
                            width: "12rem",
                        }}
                        src={require("../../resources/alpe-logo-2024-white.png")}
                        alt={"ALPE GROUP"}
                    />
                </Box>

                <Stack sx={{mx: 2, pb: 15, width: "90%", gap: 2.5}}>
                    <Box sx={{height: "2rem"}} ref={slideContainerRef}>
                        {formMsg &&
                            <Slide direction={"down"} in={!!formMsg} container={slideContainerRef.current}>
                                <Typography
                                    sx={{
                                        color: formMsg?.type === "success" ? "success.main" : "error.main",
                                        backgroundColor: formMsg?.type === "success" ? "success.light" : "error.light",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                    autoCapitalize={"100%"}
                                >
                                    {formMsg?.message}
                                </Typography>
                            </Slide>
                        }
                    </Box>
                    {!isResetting &&
                        <LoginForm
                            setIsLoading={setIsLoading}
                            setFormMsg={setFormMsg}
                            isLoading={isLoading}
                            handleStopReset={handleStopReset}
                            handleStartReset={handleStartReset}
                        />
                    }

                    {isResetting &&
                        <>
                            {!verificationCode && !resetToken &&
                                <LoginResetEmailForm
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    setFormMsg={setFormMsg}
                                    setVerificationCode={setVerificationCode}
                                    handleStartReset={handleStartReset}
                                    handleStopReset={handleStopReset}
                                />
                            }

                            {verificationCode && !resetToken &&
                                <LoginResetVerifyForm
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    setFormMsg={setFormMsg}
                                    verificationCode={verificationCode}
                                    setResetToken={setResetToken}
                                    handleStopReset={handleStopReset}
                                    handleStartReset={handleStartReset}
                                />
                            }

                            {verificationCode && resetToken &&
                                <LoginResetPasswordForm
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    setFormMsg={setFormMsg}
                                    resetToken={resetToken}
                                    handleStartReset={handleStartReset}
                                    handleStopReset={handleStopReset}
                                />
                            }
                        </>
                    }
                </Stack>
                <Typography>© 2024 Alpe Group</Typography>
            </Stack>
        </Box>
    );
};

export default LoginScreen;
