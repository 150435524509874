import {ThemeOptions} from '@mui/material/styles';
import {createTheme} from "@mui/material";

export const themeOptionsGlobal: ThemeOptions = {
    typography: {
        fontFamily: ["Outfit"].join(","),
    },

    palette: {
        primary: {
            main: '#fb6b04',
        },
        error: {
            main: "#f34d4d",
            light: "rgba(243,77,77,0.22)"
        },
        success: {
            main: "#7cf34d",
            light: "rgba(143,243,77,0.22)"
        },
        background: {
            default: "#282828"
        },
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    "-webkit-font-smoothing": "antialiased",
                    "-moz-osx-font-smoothing": "grayscale",
                    height: "100%",
                    width: "100%",
                },
            },
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: 600,
                    "&.Mui-disabled": {
                        color: "#525252"
                    }
                },
            },
        },

        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    color: "#fdfdfd"
                },
            },
        },

        MuiListItem:{
            styleOverrides:{
                root:{
                    backgroundColor: "#2f2f2f",
                    borderTop: "1px solid white",
                    borderRadius: 0,
                },
            },
        },

        MuiListItemButton: {
            styleOverrides: {

            },
        },

        MuiListItemIcon:{
            styleOverrides:{
                root:{
                    color:"#fdfdfd",
                    fontSize: "1.5rem"
                }
            },
        },

        MuiListItemText:{
            styleOverrides:{
                secondary:{
                    color:"rgba(253,253,253,0.51)"
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "#fdfdfd",
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#fdfdfd",
                },
                h6: {
                    fontWeight: 600,
                },
                subtitle2: {
                    fontWeight: 200,
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: ({theme}) => ({
                    "& input": {
                        color: "#fdfdfd"
                    },
                    '& label': {
                        color: "#fdfdfd",
                    },
                    '& label.Mui-focused': {
                        color: theme.palette.primary.main,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: theme.palette.primary.main
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: "3px",
                            borderColor: "white",
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                    },
                }),
            },
        },
    },
};

const themeGlobal = createTheme(themeOptionsGlobal);

export const THEMES = {
    themeGlobal: themeGlobal,
}
