import {API, CustomAxiosError} from "../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IUserRegistryPlantDetailed} from "../../../utils/interfaces/IUserRegistryPlantDetailed";

async function doGet(userRegistryId: number, userRegistryPlantId: number | undefined): Promise<IUserRegistryPlantDetailed> {
    try {
        const response = await API.get(`/app/user_registry/${userRegistryId}/plant/${userRegistryPlantId}`);
        return response.data.data[0];
    } catch (error) {
        throw error;
    }
}

const useApiGetUserRegistryPlantDetailed =
    (userRegistryId: number, userRegistryPlantId: number | undefined): UseQueryResult<IUserRegistryPlantDetailed, CustomAxiosError> => {
        return useQuery<IUserRegistryPlantDetailed, CustomAxiosError>({
            queryKey: ["getUserRegistryPlantDetailed"],
            queryFn: () => doGet(userRegistryId, userRegistryPlantId),
            enabled: !!userRegistryPlantId
        });
    };

export default useApiGetUserRegistryPlantDetailed;
