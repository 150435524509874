import React, {ReactNode} from "react";
import {ButtonBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ListItemProps as MuiListItemProps} from "@mui/material";
import usePostLogout from "../components/Login/api-hooks/usePostLogout";
import {useAuth} from "../utils/contexts/auth-context";

interface Props extends MuiListItemProps {
    logoutText?: string,
    logoutIcon: ReactNode,
    flipLogoutIcon?: boolean,
}

const ListItemLogout = ({logoutText = "Logout", logoutIcon, flipLogoutIcon, ...props}: Props) => {
    const navigate = useNavigate();
    const {setIsAuthenticated} = useAuth();

    const {mutateAsync: logout} = usePostLogout()

    const handleLogout = async () => {
        try {
            await logout()
            setIsAuthenticated(false)
            navigate("/login")
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <List sx={{mx: 1}} dense={true}>
            <ListItem
                component={ButtonBase}
                disableGutters={true}
                {...props}
            >
                <ListItemButton onClick={handleLogout}>
                    <ListItemIcon
                        sx={{transform: flipLogoutIcon ? "scaleX(1)" : ""}}
                    >
                        {logoutIcon}
                    </ListItemIcon>
                    <ListItemText primary={logoutText}/>
                </ListItemButton>
            </ListItem>
        </List>
    )
}

export default ListItemLogout;