import {StackProps as MuiStackProps, ButtonBase, Collapse, IconButton, Paper, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {RiArrowDropDownFill, RiArrowDropUpFill} from "react-icons/ri";

interface Props extends MuiStackProps {
    description: string | undefined
}

const ContentDetailedDesc = ({description, ...props}: Props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    return (
        <Stack
            {...props}
            sx={{
                width: "96%",
                justifyContent: "flex-start",
                textAlign: "left",
                backgroundColor: "transparent",
                p: 0.8,
                border: "1px solid white",
                borderRadius: "5px",
                display: "flex",
                my: 1
            }}
            component={Paper}
        >
            <Stack
                component={ButtonBase}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <Typography
                    fontSize={"1.1rem"}
                    fontWeight={"300"}
                    sx={{wordWrap: "break-word"}}
                >
                    Description
                </Typography>
                <IconButton size={"small"}>
                    {isExpanded ? <RiArrowDropUpFill/> : <RiArrowDropDownFill/>}
                </IconButton>
            </Stack>

            <Collapse in={isExpanded} collapsedSize={"4rem"}>
                <Typography
                    fontSize={"0.9rem"}
                    variant={"subtitle2"}
                    whiteSpace={"pre-line"}
                >
                    <p>{description}</p>
                </Typography>
            </Collapse>

            {isExpanded && description && description?.length > 500 &&
                <Stack
                    component={ButtonBase}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <Typography
                        fontSize={"1.1rem"}
                        fontWeight={"300"}
                        sx={{wordWrap: "break-word"}}
                    >
                        Close
                    </Typography>
                    <IconButton size={"small"}>
                        {isExpanded ? <RiArrowDropUpFill/> : <RiArrowDropDownFill/>}
                    </IconButton>
                </Stack>
            }
        </Stack>
    )
}

export default ContentDetailedDesc
