import {IUser} from "../interfaces/IUser";
import {API, CustomAxiosError} from "../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";

interface IWhoAmIResponse {
    user: IUser;
}

async function whoAmI(): Promise<IWhoAmIResponse> {
    try {
        const response = await API.get(`/api/whoami`);
        return response.data.data;
    } catch (error) {
        throw error as CustomAxiosError;
    }
}

const useGetWhoAmI = (): UseQueryResult<IWhoAmIResponse, CustomAxiosError> => {

    return useQuery<IWhoAmIResponse, CustomAxiosError>({
        queryKey: ["whoAmI"],
        queryFn: whoAmI
    });
};

export default useGetWhoAmI;
