import {IMachineContentRowDetailed} from "../../../../utils/interfaces/IMachineContentRowDetailed";
import ContentCardFile from "./ContentCardFile";
import ContentCardYt from "./ContentCardYt";
import ContentCardDesc from "./ContentCardDesc";
import ContentCardLink from "./ContentCardLink";

interface Props {
    row: IMachineContentRowDetailed
}

const contentCardPicker = ({row}: Props) => {

    switch (row.content_type.type) {
        case "pdf" : {
            return <ContentCardFile row={row}/>
        }
        case "img": {
            return <ContentCardFile row={row}/>
        }
        case "yt": {
            return <ContentCardYt row={row}/>
        }
        case "desc": {
            return <ContentCardDesc row={row}/>
        }
        case "link": {
            return <ContentCardLink row={row}/>
        }
        default:
            return <></>
    }
}

export default contentCardPicker;