import React, {FormEvent, useState} from 'react';
import {
    Box,
    Button, Stack,
    TextField,
    Typography
} from '@mui/material';
import {ILoginFormControl} from "./LoginScreen";
import LoadingBar from "../../ui/LoadingBar";
import usePostAccountResetVerify from "./api-hooks/usePostAccountResetVerify";

interface Props extends ILoginFormControl {
    verificationCode: string,
    setResetToken: (token: string) => void
}

const LoginResetVerifyForm = ({
                                  isLoading,
                                  setIsLoading,
                                  verificationCode,
                                  setFormMsg,
                                  handleStartReset,
                                  handleStopReset,
                                  setResetToken,
                              }: Props) => {
    const [code, setCode] = useState<string>("")

    const {mutateAsync: accountResetVerify} = usePostAccountResetVerify()

    const handleVerificationSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setFormMsg(undefined)
        setIsLoading(true)
        try {
            if (code !== verificationCode) {
                setFormMsg({type: "error", message: "Invalid verification code."})
                return
            }
            const response = await accountResetVerify({verification_code: code})
            setResetToken(response.reset_token)
        } catch (error) {
            setFormMsg({type: "error", message: "An error occurred verifying your code."})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Stack
            sx={{alignItems: "start", gap: 2.5,}}
            component="form"
            onSubmit={handleVerificationSubmit}
        >
            <Box>
                <Typography variant={"h5"}>
                    Verification
                </Typography>
                <Typography variant={"subtitle2"}>
                    Enter the verification code sent to your e-mail address to choose a new password.
                </Typography>
            </Box>
            <Stack sx={{width: "100%", gap: 2}}>
                <TextField
                    value={code}
                    label={"Verification Code"}
                    onChange={(event) => {
                        setCode(event.target.value)
                    }}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={isLoading}
                />
                <Box sx={{height: "2rem"}}>
                    {isLoading
                        ? <LoadingBar loadingLabel={"Verifying..."}/>
                        : <Button
                            type={"submit"}
                            variant="contained"
                            disabled={isLoading}
                            fullWidth={true}
                        >
                            Verify
                        </Button>
                    }
                </Box>
            </Stack>
            <Button
                variant={"text"}
                fullWidth={true}
                onClick={handleStopReset}
            >
                <Typography
                    sx={{
                        color: "primary.main",
                        fontWeight: "600",
                    }}
                    variant={"subtitle2"}
                >
                    Back to login
                </Typography>
            </Button>
        </Stack>
    );
};

export default LoginResetVerifyForm;
