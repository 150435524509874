import {Typography, TypographyProps as MuiTypographyProps} from "@mui/material";
import packageJson from "../../package.json";


const ProjectVersion = ({...props}: MuiTypographyProps) => {

    return (
        <Typography
            {...props}
            sx={{
                color: "rgba(75,75,75,0.84)",
                fontSize: "small",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
            variant={"body2"}
        >
            V. {packageJson.version}
        </Typography>
    )
}


export default ProjectVersion;