import React from 'react';
import {ProvideAuth} from "../utils/contexts/auth-context";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ThemeProvider} from "@mui/material";
import {THEMES} from "../utils/themes/global-theme";

type Props = {
    children: React.ReactNode;
}

const queryClient = new QueryClient()
const MainProviders = ({children}: Props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={THEMES.themeGlobal}>
                <ProvideAuth>
                    {children}
                </ProvideAuth>
            </ThemeProvider>

        </QueryClientProvider>
    );
};

export default MainProviders;