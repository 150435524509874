import {API, CustomAxiosError} from "../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IUserRegistryPlantDetailed} from "../../../utils/interfaces/IUserRegistryPlantDetailed";

interface IMutationData {
    userRegistryId: number,
    userRegistryPlantId: number,
    payload: any
}

async function doUpdate(data: IMutationData): Promise<IUserRegistryPlantDetailed> {
    try {
        const response = await API.putEncoded(`/app/user_registry/${data.userRegistryId}/plant/${data.userRegistryPlantId}`,
            data.payload
        );
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiUpdateUserRegistryPlant = (): UseMutationResult<IUserRegistryPlantDetailed, CustomAxiosError, IMutationData> => {
    const queryClient = useQueryClient();

    return useMutation<IUserRegistryPlantDetailed, CustomAxiosError, IMutationData>({
        mutationFn: doUpdate,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["getUserRegistryPlantDetailed"]}).finally()
        }
    });
};

export default useApiUpdateUserRegistryPlant;
