import {useMutation} from "@tanstack/react-query";
import {API} from "../../../logics/api_config";

interface AccountResetEmailResponse{
    id:number,
    created_at: string ,
    deleted_at: string | null
    email:string,
    expired_at: string | null,
    reset_at: string | null,
    reset_token:string | null,
    verification_code:string,
}

async function doPostResetEmail(payload: {email:string}): Promise<AccountResetEmailResponse & {status:string}> {
    try {
        const response = await API.postEncoded('/user/password/reset', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const usePostAccountResetEmail = () => {
    return useMutation({
        mutationFn: doPostResetEmail,
    });
};

export default usePostAccountResetEmail;
